
export const TopBanner = () => {

  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container-testeira">
        <img className="img-testeira" src="/assets/img/testeira1.jpg"
          alt="Faixada do estabelcimento da Associação Araxá Taekwondo" />
      </div>
      <div className="opacity-testeira"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up" data-aos-delay="200">
            <h1>Associação Araxá Taekwondo</h1>
            <h2>
              Situada no município de Araxá/MG, tendo como filosolia a prática
              do esporte especializado em Taekwondo e a busca pela harmonia
              através do esporte.
            </h2>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="/projects/index.html" className="btn-get-started scrollto">Veja mais</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}