import { useEffect } from "react";
import { Main } from "../../components"
import { toScroll } from "../../../utils";

export const Estatutos = () => {
  useEffect(() => {
    toScroll()
  }, []);
  return (
    <Main>
      <section id="minutes" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Atas</h2>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1tgdKRVuiuZdKBq1wnhid5Eravegc2V_W/view?usp=share_link"
                    target="_blank" rel="noreferrer">Ata Autenticada</a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1ZonOs4PjgQ9z4jQXClw362qs1SPBuER5/view?usp=sharing"
                    target="_blank" rel="noreferrer">Ata Eleição Autenticada 2023 a 2026</a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1LoOBVVNczKG4pIseKFtaLG7d57ytuerp/view?usp=sharing"
                    target="_blank" rel="noreferrer">Ata Eleição Autenticada 2023</a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1Do-oqSge4iYFa-mQVBC6DbjDDkUEjQM2/view?usp=sharing"
                    target="_blank" rel="noreferrer">ATA DA ASSEMBLÉIA EXTRORDINÁRIA - ALETRAÇÃO ESTATUTO
                    AAT</a>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/106yxk-5zgKOR5NIDGvlVcgCJrQVJs69d/view?usp=sharing"
                    target="_blank" rel="noreferrer">ata de eleição e posse diretoria AAT 2019 a 2023</a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1-RT8hAz1sEy03-riMeeYxkO9YMyuEJLC/view?usp=sharing"
                    target="_blank" rel="noreferrer">Ata de Retificação, eleição tesoureiro e aprovação contas 2021</a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1ThQ7ReyzkEkK28FQgQiXxHQoom-xLxl6/view?usp=sharing"
                    target="_blank" rel="noreferrer">Ata de eleição e posse diretoria AAT 2015 a 2019</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="notice" className="services section-bg">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Edital</h2>
          </div>

          <div className="row mb-4">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1ntAA2miaVDjNUWaHjNGmHhUskVO9ohhM/view?usp=share_link" rel="noreferrer">
                    Edital retificação CNPJ
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1VXq6U6UYHTlx3YiqYTaNO-qA-BInJxT6/view?usp=sharing" rel="noreferrer">
                    Edital de Convocação para Assembleia Geral Extraordinária
                    AAT alteração do Estatuto
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1s9XGS4ReSN_mPWWmvfrIA2ijXzsw468D/view?usp=sharing" rel="noreferrer">
                    Edital de Convocação para Assembleia Geral Extraordinária AAT 16-06
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1j8AGoROtVKjM_XaFHPZCB6AuSzB-UtqV/view?usp=sharing" rel="noreferrer">
                    Edital de Convocação para Assembleia Geral Extraordinária AAT 17-06
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1WTgbDPsu_EKsdnzjNIfarfrMYoq69-I9/view?usp=sharing" rel="noreferrer">
                    Edital de Convocação para Assembleia Geral Extraordinária AAT 18-06
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="statute" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Estatuto</h2>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1W5pFwuHVBR3SXko0GmYnomT3jKj40XxE/view?usp=share_link" rel="noreferrer">Estatuto
                    Autenticado Taekwondo</a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1YxJH56rwJEr77hpUcJlPkDUj3HqKKKcY/view?usp=sharing" rel="noreferrer">Estatuto
                    Alterado AAT 2023</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="tax-council-opinion" className="services section-bg">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Parecer Conselho Fiscal</h2>
          </div>

          <div className="row mb-4">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1zRxA4jeHMLlFuh6SmSFxl-ZDulGKNjzF/view?usp=sharing">
                    Parecer Conselho Fiscal 2021
                  </a>
                </h4>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a href="https://drive.google.com/file/d/1YTNeWnsMcdxhi19jy9t4djr1ZPhpmNTW/view?usp=sharing">
                    Parecer Conselho Fiscal 2022
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}
