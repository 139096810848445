export const Projetos = () => {

  return (
    <section id="why-us" className="why-us">
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
            <div className="content">
              <h3>
                Projetos desenvolvidos pela
                {' '}
                <strong>Associação Araxá Taekwondo</strong>
              </h3>

            </div>

            <div className="accordion-list">
              <ul>
                <li>
                  <div data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1">
                    <span>01</span>
                    {' '}
                    Projeto social Taekwondo Inclusão e superação
                    <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
                  </div>
                  <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                    <p>
                      O projeto Taekwondo – Inclusão e Superação, viabilizado pelo programa Minas Esportiva, está sendo
                      executado desde maio de 2022, pela Associação Araxá Taekwondo, beneficiando gratuitamente crianças
                      e adolescentes, entre 10 e 18 anos, com aulas e atividades de taekwondo.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style={{ backgroundImage: `url("/assets/img/projetos-img1.jpg")` }} data-aos="zoom-in" data-aos-delay="150">
            &nbsp;
          </div>
        </div>
      </div>

    </section>
  )
}