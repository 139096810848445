import { Main } from "../../components"

export const Projetos = () => {
  return (
    <Main>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Projetos Desenvolvidos Pela Associação Araxá Taekwondo</h2>
          </div>
        </div>
      </section>

      <section id="why-us" className="why-us section-bg">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="content">
                <h3>
                  Projeto social
                  <strong>Taekwondo Inclusão e superação</strong>
                </h3>
                <p>
                  O projeto Taekwondo – Inclusão e Superação, viabilizado pelo programa Minas Esportiva, está sendo
                  executado desde maio de 2022, pela Associação Araxá Taekwondo, beneficiando gratuitamente crianças e
                  adolescentes, entre 10 e 18 anos, com aulas e atividades de taekwondo.
                  <br />
                  <br />
                  Nossa equipe, através das ações, proporciona também inclusão e interatividade a alunos autistas pela
                  prática de Taekwondo. Pais e responsáveis de crianças e jovens com transtorno do espectro autismo,
                  venham somar conosco, traga seu filho ou filha, a partir de 10 anos, para se beneficiar de práticas
                  inclusivas que transformam através do esporte!!!
                  Tel.: 34 3661-4182
                  Rua José Borges de Araújo, 341 – Vl. Santa Luzia
                  Araxá/MG
                  <br />
                  <br />
                  Projeto Taekwondo: Inclusão e Superação-CA 2019.01.0040
                  Viabilizado pela Secretaria de Estado de Desenvolvimento Social, via Lei Estadual de Incentivo ao
                  Esporte de Minas Gerais,em Araxá. Por meio deste mecanismo é possível que o apoio financeiro, feito por
                  empresas, a projetos esportivos aprovados pela SEDESE, seja deduzido do saldo devedor mensal do Imposto
                  sobre Circulação de Mercadorias e Serviços, também conhecida como “ICMS Corrente”, alocando o recurso em
                  forma de esportes para a população.
                </p>
              </div>
            </div>

            <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
              style={{ backgroundImage: `url("/assets/img/projetos-img1.jpg")` }} data-aos="zoom-in" data-aos-delay="150">
              &nbsp;
            </div>
          </div>
        </div>
      </section>

    </Main>
  )
}