import { Main } from "../../components"

export const Diretoria = () => {
  return (
    <Main>
      <section id="executive-board" className="team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Composição da Diretoria da Associação Araxá Taekwondo</h2>
          </div>
          <h3>Os dirigentes listados não são remunerados.</h3>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                <div className="pic">
                  <img src="/assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Francisco Gonçalves Leal Neto</h4>
                  <span>Presidente</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4 mt-lg-0">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
                <div className="pic">
                  <img src="/assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Kennedy Avelar Borges da Costa</h4>
                  <span>Vice Presidente</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="300">
                <div className="pic">
                  <img src="/assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Milene da Costa Moreira</h4>
                  <span>Coordenadora</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="300">
                <div className="pic">
                  <img src="/assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Victor Hugo</h4>
                  <span>1ª Tesoureiro</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                <div className="pic">
                  <img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Thais Cristina Meireles da Cruz</h4>
                  <span>2ª Tesoureiro</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                <div className="pic">
                  <img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Eduardo Alexandre Gomes Mattos</h4>
                  <span>1º Secretario</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                <div className="pic">
                  <img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Eduardo Antunes Couto</h4>
                  <span>2º Secretario</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="executive-board" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Conselho Fiscal</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                <div className="pic">
                  <img src="/assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Carlos Eduardo de Almeida</h4>
                  <span>Presidente</span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4 mt-lg-0">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
                <div className="pic">
                  <img src="/assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Múcio Cardoso dos Santos</h4>
                  <span></span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="300">
                <div className="pic">
                  <img src="/assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Cleber Santos</h4>
                  <span></span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                <div className="pic">
                  <img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Pierre Alexsandro Parreira</h4>
                  <span></span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                <div className="pic">
                  <img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Maria Abadia da Silva Rosa</h4>
                  <span></span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                <div className="pic">
                  <img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                </div>
                <div className="member-info">
                  <h4>Geraldo Augusto da Silva</h4>
                  <span></span>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}