import { Main, Search } from "../../components"
import { WrapInput } from './style'
export const Pesquisa = () => {
  return (
    <Main>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Pesquisa</h2>          

            <WrapInput>
              <Search />
            </WrapInput>
          </div>
        </div>
      </section>
    </Main>
  )
}