import { Main } from "../../components"

export const Atendimento = () => {
  return (
    <Main>
      <section id="assistance" className="portfolio">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Associação Araxá Taekwondo</h2>
            <p>
              Rua: José Borges de Araújo, nº 341, bairro: Santa Luzia / Araxá,
              MG – Brasil <br />
              Tel contato: (34) 3661:4182 <br />
              E-mail: evandroatclube@gmail.com <br />
              Horário de Funcionamento: 08:00 ás 11:00 – 13:30 ás 17:30 – Seg à
              Sex <br />
              Para informações acessar o link:
              <a href="https://associacaoaraxataekwondo.com.br/">www.associacaoaraxataekwondo.com.br/</a>
            </p>
            <h5 className="mt-5">
              OS ATENDIMENTOS REALIZADOS NA ASSOCIAÇÃO ARAXÁ TAEKWONDO SÃO TODOS
              GRATUITOS, PORTANTO NÃO EXISTE COBRANÇA DE NENHUMA TAXA DE
              MATRÍCULA OU MENSALIDADE.
            </h5>
          </div>
        </div>
      </section>
    </Main>
  )
}