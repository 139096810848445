import { listPhotos } from '../../../helpers'

export const Fotos = () => {
  return (
    <section id="portfolio" className="portfolio section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Fotos</h2>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">
          {listPhotos.map((f) => (
            <div key={f.src} className="col-lg-4 col-md-6 portfolio-item filter-card">
              <div className="portfolio-img imgs">
                <a href={f.src} className="portfolio-lightbox preview-link">
                  <img src={f.src} className="img-fluid" alt={f.alt as unknown as string} />
                </a>
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>
  )
}