import styled from 'styled-components'
import {Search} from 'react-feather'

export const StyleIcon = styled(Search)`
color:#bbb;
`

export const WrapInput = styled.div`
width:600px;
height: auto;
display: flex;
  @media (max-width: 768px) {    
    width: 90%;    
    margin-top: -20px;
  }
`

export const InputStyled = styled.input `
width: 100%;
outline: none;
border: none;
background: transparent;
margin-left: 5px;
`