import React from 'react'
import { WrapInput, StyleIcon, InputStyled } from './style'


type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
}
export const Input: React.FC<Props> = ({ onChange }) => {

  return (
    <WrapInput className="form-control" >
      <StyleIcon />
      <InputStyled type="text" onChange={onChange} placeholder='Pesquisar...' autoFocus />
    </WrapInput>
  )
}