import { Main } from "../../components"

export const Legislacao = () => {

  return (
    <Main>
      <section id="" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>LEGISLAÇÃO, PORTARIA E RESOLUÇÕES</h2>
          </div>

          {/* <div className="row">
            <div
              className="col-xl-3 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div
                className="icon-box pdf-icon-box d-flex align-items-center flex-column"
              >
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4><a href="">Arquivo "x"</a></h4>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </Main>
  )
}