import { Main } from "../../components";
import { QuemSomos } from "./quem-somos";
import { Noticias } from "./noticias";
import { Projetos } from "./projetos";
import { Fotos } from "./fotos";
import { Equipe } from "./equipe";
import { Contato } from "./contato";
export const HomePage = () => {
  return (
    <Main disableScrrol>
      <QuemSomos />
      <Noticias />
      <Projetos />
      <Fotos />
      <Equipe />
      <Contato />
    </Main>
  );
};
