import { Main } from "../../components"

export const Taekwondo = () => {

  return (
    <Main>
      <section id="taekwondo" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>A História do Taekwondo</h2>
            <p className="text-align-left">
              O Taekwondo é uma arte marcial coreana que foi desenvolvida ao longo de milênios. Sua história remonta à
              península coreana, onde as artes marciais eram praticadas como uma forma de autodefesa e treinamento
              militar.
              <br />
              <br />
              No século VI d.C., durante o Reino de Silla, foi estabelecido o "Hwarang", um grupo de jovens guerreiros que
              estudavam artes marciais e valores morais. Esses guerreiros desempenharam um papel importante na formação e
              disseminação do Taekwondo.
              <br />
              <br />
              Durante o período da ocupação japonesa na Coreia (1910-1945), o Japão proibiu a prática das artes marciais
              coreanas. No entanto, isso não impediu que os coreanos mantivessem suas tradições marciais vivas, praticando
              secretamente.
              <br />
              <br />
              Após a libertação da Coreia em 1945, vários mestres de artes marciais se uniram para desenvolver uma forma
              unificada de arte marcial coreana. Em 1955, foi fundada a Associação Coreana de Taekwondo, estabelecendo um
              currículo padronizado e um sistema de graduação.
              <br />
              <br />
              Em 1973, o Taekwondo foi reconhecido como esporte oficial e tornou-se parte dos Jogos Olímpicos. Desde
              então, o Taekwondo tem ganhado popularidade em todo o mundo como uma arte marcial competitiva e como uma
              forma de exercício físico e mental.
              <br />
              Hoje, o Taekwondo é praticado em mais de 200 países e é conhecido por suas técnicas de chutes rápidos e
              poderosos, além de enfatizar a disciplina, o respeito e os valores éticos.
            </p>
          </div>
        </div>
      </section>
    </Main>
  )
}