import React, { Children, useEffect } from 'react'
import { TopBanner } from '../top-banner'
import { TopMenu } from '../top-menu'
import { Footer } from '../footer'
import { Helmet } from 'react-helmet'
import { toScrollSection } from '../../../utils'

interface Props {
  children: any,
  disableScrrol?: boolean
}

export const Main: React.FC<Props> = ({ children, disableScrrol }) => {

  useEffect(() => {
    toScrollSection("main")
  }, []);

  return (
    <>
      <Helmet>
        <script src="/assets/js/main.js" type="text/javascript" />
      </Helmet>
      <TopMenu />
      <TopBanner />
      <div id={disableScrrol ? "" : "main"}>
        {Children.map(children, child => child)}
      </div>
      <Footer />
    </>
  )
}