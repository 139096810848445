import { Main } from "../../components"
export const Noticias = () => {
  return (
    <Main>
      <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Notícias</h2>
          </div>

          <div id="main-notices" className="main-notices d-flex align-items-center flex-column section-bg row">
            <div>
              <a target="_blank" href="https://drive.google.com/file/d/1SssJKU-9Weyjdpl_vSBPrOTwc3J78R58/view?usp=sharing"
                data-aos="zoom-in" data-aos-delay="100" rel="noreferrer">
                <div className="card mb-3 p-4" style={{ maxWidth: "1240px" }}>
                  <div className="row g-0">
                    <div className="col-md-4">
                      <img style={{ height: "200px" }} src="/assets/img/logo.jpg" className="img-fluid rounded-start" alt="..." />
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h3 className="card-title fs-4">
                          Edital de Convocação para Assembleia Geral
                          Extraordinária da Associação Araxá Taekwondo 02 de
                          maio de 2023
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}