import { Link } from "../../../router/links"
import { Main } from "../../components"

export const ExecucaoDeProjetos = () => {
  return (
    <Main>
      <section id="project-execution" className="transparency team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Execução De Projetos</h2>
          </div>
          <div className="row">
            <a href={Link.execucaoDeProjetos.relatorios}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Relatório De Execução</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.execucaoDeProjetos.publicacoes}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Publicações</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.execucaoDeProjetos.termosDeCompromisso}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Termos De Compromisso</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Main>
  )
}