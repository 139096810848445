import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Page from "../site/pages";
import { Link } from "./links";

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path={Link.home} element={<Page.HomePage />} />
      <Route path={Link.noticias} element={<Page.Noticias />} />
      <Route path={Link.projetos} element={<Page.Projetos />} />
      <Route path={Link.estrutura} element={<Page.Estrutura />} />
      <Route path={Link.diretoria} element={<Page.Diretoria />} />
      <Route path={Link.taekwondo} element={<Page.Taekwondo />} />
      <Route path={Link.atendimento} element={<Page.Atendimento />} />
      <Route path={Link.financeiro} element={<Page.Financeiro />} />
      <Route
        path={Link.execucaoDeProjetos.home}
        element={<Page.ExecucaoDeProjetos />}
      />
      <Route
        path={Link.execucaoDeProjetos.relatorios}
        element={<Page.RelatorioDeGestao />}
      />
      <Route
        path={Link.execucaoDeProjetos.publicacoes}
        element={<Page.Publicacoes />}
      />
      <Route
        path={Link.execucaoDeProjetos.termosDeCompromisso}
        element={<Page.TermosDeCompromisso />}
      />
      <Route path={Link.compras.home} element={<Page.Compras />} />
      <Route
        path={Link.compras.editalDeContratacao}
        element={<Page.EditalDeContratacao />}
      />
      <Route path={Link.compras.licitacoes} element={<Page.Licitacoes />} />
      <Route path={Link.compras.notasFiscais} element={<Page.NotasFiscais />} />
      <Route path={Link.estatutos.home} element={<Page.Estatutos />} />
      <Route
        path={Link.perguntasERespostas}
        element={<Page.PerguntasResposats />}
      />
      <Route
        path={Link.relatorioDeGestao}
        element={<Page.RelatorioDeGestao />}
      />
      <Route path={Link.legislacao} element={<Page.Legislacao />} />
      <Route path={Link.sobre} element={<Page.Sobre />} />
      <Route path={Link.transparencia} element={<Page.Transparencia />} />
      <Route path={Link.pesquisa} element={<Page.Pesquisa />} />
      <Route path={Link.regimentoInterno} element={<Page.RegimentoInterno />} />
    </Routes>
  </BrowserRouter>
);
