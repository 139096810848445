import { Link } from "../../../router/links"
import { Main } from "../../components"

export const Transparencia = () => {

  return (
    <Main>
      <section id="transparency" className="transparency team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Links</h2>
          </div>
          <div className="row">
            <a href={Link.atendimento}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Atendimento</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.diretoria}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Diretoria</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.financeiro}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Financeiro</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.execucaoDeProjetos.home}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Execução de Projetos</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.compras.home}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Compras</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.estatutos.home}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Estatutos e Assembleias</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.perguntasERespostas}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Perguntas e Respostas Frequentes</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.relatorioDeGestao}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Relatório de Gestão e Execução Orçamentária</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.legislacao}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Legislação, Portaria e Resoluções</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Main>
  )
}