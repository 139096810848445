import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Router } from './router'
import { startAccessibility } from './utils';
window.addEventListener('load', startAccessibility, false)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Router />
);
reportWebVitals();
