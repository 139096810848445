import { Link } from "../../../router/links"


export const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>AAT</h3>
              <p>
                Rua: José Borges de Araújo, nº 341, <br />
                Bairro Santa Luzia <br />
                Araxá, MG – Brasil <br /><br />
                <strong>Telefone:</strong> +55 (34)3661-4182<br />
                <strong>Email:</strong> evandroatclube@gmail.com<br />
              </p>
            </div>

            <div className="col-lg-6 col-md-6 footer-links">
              <h4 className="text-center">Links Úteis</h4>
              <div className="row">
                <div className="col-lg-6 fotter-links-padding">
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href={Link.home}>Pagina Inicial</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href={Link.sobre}>AAT</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href={Link.noticias}>Notícias</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href={Link.projetos}>Projetos</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 fotter-links-padding">
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href="/#portfolio">Galeria</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href={Link.transparencia}>Transparência</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <a href="/#contact">Contato</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Nossas Redes Sociais</h4>

              <div className="social-links mt-3">
                <a target="_blank" href="https://www.facebook.com/assocaicaoaraxatkd" className="facebook" rel="noreferrer"><i className="bx bxl-facebook"></i></a>
                <a target="_blank" href="https://www.instagram.com/aat_associacao_araxa_taekwondo/" className="instagram" rel="noreferrer"><i
                  className="bx bxl-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright <strong><span>Associação Araxá Taekwondo</span></strong>. Todos os Direitos Reservados
        </div>
      </div>
    </footer>
  )
}