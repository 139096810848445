import { Main } from "../../../components"

export const NotasFiscais = () => {

  return (
    <Main>
      <section className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="row">
            <div className="section-title">
              <h2>Comprovantes de Pagamentos</h2>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img
                      className="pdf-icon"
                      src="/assets/img/pdf-icon.png"
                      alt="Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO"
                    />
                  </div>
                  <h4><a target="_blank" href="https://drive.google.com/file/d/1kf7S3Vqz2f1I8igtsZy7CSGhoc_1pnQ0/view" rel="noreferrer">Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO 01-10</a></h4>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img
                      className="pdf-icon"
                      src="/assets/img/pdf-icon.png"
                      alt="Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO"
                    />
                  </div>
                  <h4><a target="_blank" href="https://drive.google.com/file/d/1KQPbzOAzUM3BhcN2peaZxVpkFBUesxJP/view" rel="noreferrer">Nota Fiscal Camisa Uniforme</a></h4>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img
                      className="pdf-icon"
                      src="/assets/img/pdf-icon.png"
                      alt="Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO"
                    />
                  </div>
                  <h4><a target="_blank" href="https://drive.google.com/file/d/1eOyh5I8aMSzW3gkcAY5SHmDDvcFledNj/view" rel="noreferrer">Nota Fiscal de Dobok de Taekwondo</a></h4>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img
                      className="pdf-icon"
                      src="/assets/img/pdf-icon.png"
                      alt="Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO"
                    />
                  </div>
                  <h4><a target="_blank" href="https://drive.google.com/file/d/1orEuIdKc4NxYQ6xgcOuS39M4mcEm-kb8/view" rel="noreferrer">Nota Fiscal Elaboração e Captação TAEKONDO TRANSFORMAÇÃO</a></h4>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img
                      className="pdf-icon"
                      src="/assets/img/pdf-icon.png"
                      alt="Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO"
                    />
                  </div>
                  <h4>
                    <a target="_blank" href="https://drive.google.com/file/d/1FXMw67OXvXtOM0ReuZp2rXPnWg7VyLoE/view" rel="noreferrer">
                      Nota Fiscal Exames Médicos Admissionais
                    </a>
                  </h4>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img
                      className="pdf-icon"
                      src="/assets/img/pdf-icon.png"
                      alt="Nota Fiscal Assessoria e Consultoria TAEKWONDO TRANSFORMAÇÃO"
                    />
                  </div>
                  <h4>
                    <a target="_blank" href="https://drive.google.com/file/d/1nU9swMDQA4pFm4KGHDaw-Q1EvVFVZZZv/view" rel="noreferrer">
                      Nota Fiscal Notebook
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}