import { Accessibility, IAccessibilityIconOptions, IAccessibilityOptions } from 'accessibility'
const labels:IAccessibilityOptions["labels"] = {
    resetTitle: 'Restaurar',
    closeTitle: 'close (in my language)',
    menuTitle: 'Acessibilidade',
    increaseText: 'Aumentar fonte',
    decreaseText: 'Diminuir fonte',
    increaseTextSpacing: 'Aumentar espaçamento do texto',
    decreaseTextSpacing: 'Diminuir espaçamento do texto',
    increaseLineHeight: 'Aumentar altura da linha',
    decreaseLineHeight: 'Diminuir altura da linha',
    invertColors: 'Inverter cores',
    grayHues: 'Aplicar cor cinza',
    underlineLinks: 'Destacar links',
    bigCursor: 'Aumentar o cursor',
    readingGuide: 'Aplicar guia de linha',
    textToSpeech: 'Converter texto em voz',
    speechToText: 'Converter voz em texto',
    disableAnimations: 'Desabilitar animações',
    screenReader:'Ler tela'
};


const icon:IAccessibilityIconOptions = {
  position:{
    bottom:{ size: 120, units: 'px', },
  }
}

export const startAccessibility = ()=>{
  return new Accessibility({labels, icon}) 
}