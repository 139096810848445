import { Main } from '../../components/main'
export const Estrutura = () => {

  return (
    <Main>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Associação Araxá Taekwondo</h2>
            <p className="text-align-left">
              Surgiu em 1989 a primeira academia desta arte marcial, através do professor Ivan Carlos da Silva, Faixa
              Preta 1º DAN, mais esta teve seu registro em 1992, conforme consulta realizada no cartório de registro desta
              cidade, onde tornou-se oficializada pelo professor Jefferson Vicente de Paula, sendo que o mesmo esteve a
              frente da academia por quatro anos na cidade, tendo vários resultados significativos nas diversas
              competições de Tae Kwon Do que participou no estado de Minas Gerais, levando o nome de Araxá, neste período
              teve vários alunos que se destacaram nas competições municipais e estaduais.
              <br />
              Estas conquistas estão registradas em diversas publicações da mídia local e com isso contribuiu para que
              este esporte se expandir
              na cidade, com mais adeptos. Em 1996, com a saída do Professor Jeferson, à Academia Araxá Tae Kwon Do Clube,
              este repassou as atividades desta academia para o professor que hoje se encontra nela e que a partir deste
              momento fez com que este esporte crescesse ainda mais na cidade e com o passar do tempo e do crescimento
              desta modalidade de arte marcial, fez necessário a criação da Associação Municipal de Tae kwon Do de Araxá -
              MG, sendo a mesma reconhecida pelos trabalhos realizados junto à comunidade, foi em 28 de Julho de 2005,
              reconhecida como uma entidade de utilidade pública nesta cidade através da lei nº. 4918.
              <br /><br />
              Esta associaçãoabriu as portas para realizar trabalho voluntários de ensinar o Tae Kwon Do, as crianças
              carentes da cidade,
              tendo resultados expressivos não só no aspecto social de contribuir na formação destas crianças, como também
              no lado esportivo formando atletas com resultados significativos tanto regional como estadual, neste sentido
              em 2009, tendo este trabalho reconhecido pela Assembléia Legislativa Estadual reconhecendo esta associação
              como uma entidade de utilidade estadual.
              <br /><br />
              A associação foi a primeira academia de artes marciais do Brasil a
              conquistar o selo empresa amiga da criança por fazer trabalhos sociais desde 1999, com as crianças da
              própria entidade, com programas como Tae Kwon Do Combate a Fome, Tae Kwon Do Amigo da Escola, parceiros no
              Projeto Meninos da Chapinha, a Academia alem disso arrecada alimentos para as crianças mais necessitadas e
              as entregas no dia das crianças, por esses vários trabalhos feitos, a academia conquistou esse selo.
              Em Fevereiro de 2007, esta associação, iniciou um trabalho experimental com Pessoas com deficiência visual,
              com o intuito de proporcionar a estas pessoas mais uma modalidade esportiva além da oportunidade de
              aprenderem e praticarem defesa pessoal com o Tae Kwon DO, sendo este trabalho pioneiro no Brasil.<br />
              Ainda
              neste mesmo ano este esporte foi iniciado no Campus do UNIARAXA, como projeto de extensão para ensinar esta
              arte marcial as crianças carentes do entorno deste campus. Um fator importante que levantamos nesta pesquisa
              foram os benefícios que o esporte trouxe aos seus praticantes, sendo muitas oportunidade além dos benefícios
              de praticar uma atividade física e também contribuir para a inclusão não só de pessoas com deficiência e as
              pessoas que não teriam condições de freqüentarem uma academia e neste sentido contribuindo com a melhora da
              auto-estima e a superação de obstáculos, e com isto desenvolvendo suas habilidade e o mais importante é a
              melhoria da qualidade de vida.
              <br /><br />
              A historia do Tae Kwon Do em Araxá vem sendo escrita por muitos atletas que
              se dispuseram a dedicar ao esporte. Isso, graças ao mestre Jefferson Vicente de Paula, no qual
              não mediu esforços para fazer a divulgação da modalidade na cidade, onde deixou seu legado na associação de
              Tae kwon do da cidade, onde a mesma continua incansavelmente no propósito de difundir esta modalidade não só
              na cidade de Araxá como também na sua região e neste sentido com o seu trabalho para desenvolvimento do
              esporte, do qual a maioria das pessoas conhecem este trabalho.
            </p>
          </div>
        </div>
      </section>
    </Main>
  )
}