/* eslint-disable jsx-a11y/iframe-has-title */
export const Contato = () => {
  return (
    <section id="contact" className="contact section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contato</h2>
        </div>
        <div className="row">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Endereço:</h4>
                <p>
                  Rua José Borges de Araújo, nº 341, <br />
                  Bairro Santa Luzia <br />
                  Araxá, MG – Brasil
                </p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>evandroatclube@gmail.com</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Telefone:</h4>
                <p>+55 (34)3661-4182</p>
              </div>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234.92007948610305!2d-46.94560561018208!3d-19.596438463547337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b0371ea0c9068f%3A0x9e7d91a02d7b9753!2sAssocia%C3%A7%C3%A3o%20Araxaense%20de%20Teakwondo!5e0!3m2!1spt-BR!2sbr!4v1673995435172!5m2!1spt-BR!2sbr" style={{ border: 0, width: "100%", height: 290 }} allowFullScreen />
            </div>
          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Nome Completo</label>
                  <input type="text" name="name" className="form-control" id="name" required />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">Seu Email</label>
                  <input type="email" className="form-control" name="email" id="email" required />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Assunto</label>
                <input type="text" className="form-control" name="subject" id="subject" required />
              </div>
              <div className="form-group">
                <label htmlFor="name">Mensagem</label>
                <textarea className="form-control" name="message" rows={10} required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Sua mensagem foi enviada! Nossa equipe agradeçe!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Enviar Mensagem</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}