import { Container, ContainerItens, Link } from './style'
import { Input } from './input'
import { SearchBaseItem, searchBase } from '../../data/data'
import { useState } from 'react'

export const Search = () => {
  const [result, setResult] = useState<SearchBaseItem[]>([])

  const filterInBase = (word: string) => {
    const searchedPositions: number[] = []

    searchBase.forEach((value, i) => {
      const searched = value[i].keyword.toLocaleLowerCase().includes(word.toLocaleLowerCase())
      if (searched) searchedPositions.push(i)
    })

    return searchedPositions.map((i) => searchBase[i][i])
  }


  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase()
    const filtered = filterInBase(value)
    setResult(filtered)

    if (value.length === 0) setResult([])
  }

  return (
    <Container>
      <Input onChange={onChange} />
      <ContainerItens>
        {result.map((item) => (
          <Link href={item.link} key={item.link}>{item.title}</Link>
        ))}
      </ContainerItens>
    </Container>
  )

}