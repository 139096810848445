import { Link } from "../../../router/links"
import { Main } from "../../components"

export const Compras = () => {
  return (
    <Main>
      <section id="project-execution" className="transparency team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Compras</h2>
          </div>
          <div className="row">
            <a href={Link.compras.editalDeContratacao}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Edital de Contratação de Recursos Humanos</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.compras.licitacoes}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Licitações</h4>
                  </div>
                </div>
              </div>
            </a>
            <a href={Link.compras.notasFiscais}>
              <div className="col-lg-12 mb-4">
                <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                  <div className="member-info">
                    <h4>Notas Fiscais</h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Main>
  )
}