import { Link } from "../../../../router/links"

export const QuemSomos = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Quem somos</h2>
        </div>

        <div className="row content">
          <div className="col-lg-12 pt-4 pt-lg-0 center">
            <p>
              A Associação Araxá Taekwondo foi fundada em maio de 1992, tendo
              sua primeira denominação, Araxá Taekwondo Clube. Situada no
              município de Araxá/MG, tendo como filosolia a prática do esporte
              especializado em Taekwondo e a busca pela harmonia através do
              esporte. Desde a sua criação, a meta é usar também o Taekwondo
              para inserção e reinserção de pessoas na sociedade, pois ao
              identificar a demanda de crianças e adolescentes ociosos,
              principalmente pela prática de esportes, criou um grupo de
              apoio, usando a arte marcial como alternativa para educação
              através do esporte, aliado a práticas cidadãs, envolvimento
              familiar e um modo de lazer, desenvolvendo projetos em parceria
              com outras instituições, levando o Taekwondo a todos os cantos
              de nossa cidade.
            </p>
            <a href={Link.sobre} className="btn-learn-more">Conheça Mais</a>
          </div>
        </div>
      </div>
    </section>
  )
}