import { Main } from "../../components"

export const PerguntasResposats = () => {
  return (
    <Main>
      <section id="why-us" className="why-us">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
              <div className="content section-title">
                <h3>Perguntas e Respostas Frequentes</h3>
                <p></p>
              </div>

              <div className="accordion-list">
                <ul>
                  <li>
                    <div data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1">
                      <span>01</span>
                      {' '}
                      Quando terá inscrição para participar dos projetos?
                      <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
                    </div>
                    <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                      <p>
                        As inscrições, são programadas por nossa equipe durante o ano e avisadas previamente por postagens
                        e chamadas em nosso site e redes sociais.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed">
                      <span>02</span>
                      {' '}
                      As inscrições são pagas?
                      <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
                    </div>
                    <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        Não, nossos atendimentos dos projetos são totalmente gratuitos, não são cobradas nenhuma taxa de
                        matrícula ou mensalidade.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed">
                      <span>03</span>
                      {' '}
                      Quais as faixas etárias são atendidas pelos projetos sociais?
                      <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
                    </div>
                    <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                      <p>Atualmente temos atendimentos de 9 a 17 anos.</p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" data-bs-target="#accordion-list-4" className="collapsed">
                      <span>04</span>
                      {' '}
                      Quais materiais levar para as atividades esportivas dos projetos?
                      <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
                    </div>
                    <div id="accordion-list-4" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        Levar os materiais de uso pessoal, calça esportiva e camiseta.
                      </p>
                    </div>
                  </li>

                  <li>
                    <div data-bs-toggle="collapse" data-bs-target="#accordion-list-5" className="collapsed">
                      <span>05</span>
                      {' '}
                      Como faço para fazer minha inscrição?
                      <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
                    </div>
                    <div id="accordion-list-5" className="collapse" data-bs-parent=".accordion-list">
                      <p>
                        Basta os responsáveis procurarem a sede social da associação para se inscreverem em nossos
                        projetos. As vagas são preenchidas de acordo a procura e/ou desistência de algum (a) aluno (a).
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}