import { Main } from "../../components";

export const RegimentoInterno = () => {
  return (
    <Main>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Rgimento interno</h2>
          </div>
        </div>
      </section>
      <section id="budget-management-report" className="section-bg">
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div id="index-composition" className="services">
              <div className="container padding-top-bottom" data-aos="fade-up">
                <div className="row">
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1vxGnm2zHo6eRLIUOU8dFuIayM-Rij4cz/view"
                          rel="noreferrer"
                        >
                          Certificados FTEMG dos atletas
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1BDcQS2eBeynOxFT8AVgfWEcC69EQsNZm/view"
                          rel="noreferrer"
                        >
                          Declaração de transferência dos Atletas
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Main>
  );
};
