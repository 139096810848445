import { Main } from "../../../components"

export const TermosDeCompromisso = () => {
  return (
    <Main>
      <section id="engagement-terms" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Termos de Compromisso</h2>
          </div>

          <div className="row mb-4">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <a href="https://drive.google.com/file/d/1OAaD3k5L9fcLReqYYIV192suI6nuMp5a/view" target="_blank" rel="noreferrer">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                  </div>
                  <h4>Termo de Compromisso Taekwondo Transformação</h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}