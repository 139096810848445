import { Children } from 'react'
import { IconSearchStyled, WrapIconMob } from './style'
interface Props {
  children: any
}

export const MenuSearch = () => {
  return (
    <IconSearchStyled />
  )
}
export const MenuSearchMob: React.FC<Props> = ({ children }) => {
  return (
    <WrapIconMob>
      {Children.map(children, child => child)}
    </WrapIconMob>
  )
}

