export const Noticias = () => {
  return (
    <section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Notícias</h2>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <a href="https://drive.google.com/file/d/1SssJKU-9Weyjdpl_vSBPrOTwc3J78R58/view?usp=sharing">
              <div className="icon-box">
                <div className="icon">
                  <img style={{ height: 250 }} className="news-img" src="/assets/img/logo.jpg" alt="" />
                </div>
                <h4>Convocação para Assembleia Geral</h4>
                <p className="text-black">
                  Edital de Convocação para Assembleia Geral Extraordinária da
                  Associação Araxá Taekwondo 02 de maio de 2023
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}