import { useEffect } from "react";
import { toScroll } from "../../../utils/to-scroll";
import { Main } from "../../components"

export const Financeiro = () => {
  useEffect(() => {
    toScroll()
  }, []);

  return (
    <Main>
      <section id="balance-sheets" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Balancetes</h2>
          </div>

          <div className="row">
            <a href="https://drive.google.com/file/d/1RkSQqgYC7CHtZuaREeQcGIZlO9a9M9bK/view" target="_blank" rel="noreferrer">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                  <div className="icon">
                    <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                  </div>
                  <h4>Fluxode Caixa 2022</h4>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section id="patrimony-balance-sheet" className="services section-bg">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Balanço Patrimonial</h2>
          </div>
          <div className="row mb-4">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank" href="https://drive.google.com/file/d/1I8MaQm34Hv_1nDE6I1tAD_qLZQFZVL9q/view" rel="noreferrer">
                    Balanço Patrimonial AAT 2022
                  </a>
                </h4>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1K1GWFkhA8XaYkvXq0Pp-xJKxLQ_t3wQL/view" rel="noreferrer">
                    Declaração sem movimento 2021
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="index-composition" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Composição De Índices</h2>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank" href="https://drive.google.com/file/d/1jEO-z-JWRBpdNQoRJJzmxMBrzsAxnMHT/view" rel="noreferrer">
                    COMPOSIÇÃO DE ÍNDICES DO ART. 4º DA PORTARIA 115-2018
                    2022
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="payments-statement" className="services section-bg">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>Demonstrativo De Pagamento De Beneficiários</h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/185zIBIIrZbdUTiBOQZCt-e9mL5m71-BY/view" rel="noreferrer">
                    Relação de recursos humanos contratados
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="dre" className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="section-title">
            <h2>DRE</h2>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1cQyweMAETI5lPNjolpiqOC2T4zlu2TQm/view" rel="noreferrer">
                    DRE 2022
                  </a>
                </h4>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                <div className="icon">
                  <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                </div>
                <h4>
                  <a target="_blank"
                    href="https://drive.google.com/file/d/1K1GWFkhA8XaYkvXq0Pp-xJKxLQ_t3wQL/view" rel="noreferrer">
                    Declaração sem movimento 2021
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}