export interface SearchBaseItem {
    keyword: string;
    title: string;
    link: string;
}
export interface SearchBase {
    [key: number]: SearchBaseItem;
}

export const searchBase: SearchBase[] = [
    {
        0: {
            keyword: "Noticias Edital de Convocação para Assembleia Geral Extraordinária da Associação Araxá Taekwondo 02 de maio de 2023",
            title: "Edital de Convocação para Assembleia Geral Extraordinária da Associação Araxá Taekwondo 02 de maio de 2023",
            link: "/noticias",
        },
    },
    {
        1: {
            keyword: "Fotos galeria",
            title: "Galeria de fotos",
            link: "/#portfolio",
        },
    },
    {
        2: {
            keyword: "ASSOCIAÇÃO ARAXÁ TAEKWONDO ASSOCIACAO ARAXA TAEKWONDO",
            title: "Sobre a Associção Araxá Taekwondo",
            link: "/estrutura",
        },
    },
    {
        3: {
            keyword: "COMPOSIÇÃO DA DIRETORIA DA ASSOCIAÇÃO ARAXÁ TAEKWONDO COMPOSICAO DA DIRETORIA presidente tesoureiro secretario",
            title: "Composição Da Diretoria Da Associação Araxá Taekwondo",
            link: "/diretoria",
        },
    },
    {
        4: {
            keyword: "Atendimento GRATUITOS gratis sem taxta",
            title: "Sobre o atendimento",
            link: "/atendimento",
        },
    },
    {
        5: {
            keyword: "financeiro fluxo de caixa balanço patrimonial balanco declaracão composição de índices composicao de indices  ART. 4º DA PORTARIA 115-2018 2022 DEMONSTRATIVO DE PAGAMENTO DE BENEFICIÁRIOS RH  recursos humanos contratados",
            title: "Financeiro",
            link: "/financeiro",
        },
    },
    {
        6: {
            keyword: "telefone contato instagram facebook",
            title: "Contato",
            link: "/#contact",
        },
    },
    {
        7: {
            keyword: "transparencia transparência",
            title: "Transparência",
            link: "/transparencia",
        },
    },
    {
        8: {
            keyword: "relatorios relátorio gestão financeira técnica",
            title: "Relátorio de Gestão Financeira e Técnica",
            link: "/relatorios-de-execucoes",
        },
    },    
    {
        9: {
            keyword: "publicacoes publicações",
            title: "Publicações",
            link: "/publicacoes",
        },
    },
    {
        10: {
            keyword: "termos de compromisso",
            title: "Termo de Compromisso Taekwondo Transformação",
            link: "/termos-de-compromisso",
        },
    },
    {
        11: {
            keyword: "licitações licitacoes compras" ,
            title: "Licitações e editais de compras",
            link: "/licitacoes",
        },
    },
    {
        12: {
            keyword: "edital editais de contratação contratacao recursos humanos rh compras" ,
            title: "Edital de contratação de Recusos Humanos",
            link: "/edital-de-contratacao",
        },
    },
    {
        13: {
            keyword: "notas fiscais nota fiscal compras pagamentos" ,
            title: "Comprovantes de pagamentos",
            link: "/notas-fiscais",
        },
    },
    {
        14: {
            keyword: "estatutos assembleias atas" ,
            title: "Atas",
            link: "/estatutos-e-assembleias#minutes",
        },
    },
    {
        15: {
            keyword: "estatutos assembleias edital editais" ,
            title: "Edital",
            link: "/estatutos-e-assembleias#notice",
        },
    },
    {
        16: {
            keyword: "estatutos assembleias" ,
            title: "Estatuto alterado e autenticado",
            link: "/estatutos-e-assembleias#statute",
        },
    },
    {
        17: {
            keyword: "conselho fiscal parecer" ,
            title: "Parecer conselho fiscal",
            link: "/estatutos-e-assembleias#tax-council-opinion",
        },
    },
    {
        18: {
            keyword: "legislação legislacao portarias resoluções resolucoes" ,
            title: "Legislação portaria e resoluções",
            link: "/legislacao",
        },
    },
    {
        19: {
            keyword: "perguntas respostas duvidas" ,
            title: "Perguntas e respostas",
            link: "/perguntas-e-respostas",
        },
    },
    {
        20: {
            keyword: "balancetes patrimonial financeiro" ,
            title: "Balanço patrimonial",
            link: "/financeiro#patrimony-balance-sheet",
        },
    },
    {
        21: {
            keyword: "dre financeiro" ,
            title: "DRE",
            link: "/financeiro#dre",
        },
    },
    {
        22: {
            keyword: "composição composicao de indices índices financeiro",
            title: "Composição de índices",
            link: "/financeiro#index-composition",
        },
    },
];
