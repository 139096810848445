import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const ContainerItens = styled.div`
    width: 600px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    flex-direction: column;
    @media (max-width: 768px) {    
        width: 90%;    
    }
`;

export const Link = styled.a`
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 20px;
    cursor: pointer;
`;
