import {Search} from 'react-feather'
import styled from 'styled-components'

export const WrapIcon = styled.div`
  border-radius: 4px;
  border: 1px solid #fff;
`
export const WrapIconMob = styled.div`
  display: none;
  @media (max-width: 768px) {    
    display: block;
    margin-right:5px; 
  }
`

export const IconSearchStyled = styled(Search)` 
  color:#fff;
  margin-left:5px;  

`
