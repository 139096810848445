import { Main } from "../../components"

export const RelatorioDeGestao = () => {

  return (
    <Main>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Relatório de Gestão e Execução Orçamentária</h2>
          </div>
        </div>
      </section>
      <section id="budget-management-report" className="section-bg">
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Relatório de Gestão Financeira</h2>
            </div>
            <div id="index-composition" className="services">
              <div className="container padding-top-bottom" data-aos="fade-up">
                <div className="row">
                  <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                      </div>
                      <h4>
                        <a target="_blank" href="https://drive.google.com/file/d/1H9Xfg7ktZdWq0xdn6mv_MMLRy6c3MJ8s/view" rel="noreferrer">
                          Relatório de Gestão Financeira 2022
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section id="technical-management-report">
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Relatório de Gestão Técnica</h2>
            </div>
            <div id="index-composition" className="services">
              <div className="container padding-top-bottom" data-aos="fade-up">
                <div className="row">
                  <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                      </div>
                      <h4>
                        <a href="https://drive.google.com/file/d/1MDsh6i9lIUx3438cefQYqxeUzEbOJr0E/view?usp=sharing">
                          Relatório de Gestão Técnica.pdf
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Main>
  )
}