import { Main } from "../../../components"

export const EditalDeContratacao = () => {

  return (
    <Main>
      <section className="services">
        <div className="container padding-top-bottom" data-aos="fade-up">
          <div className="row">
            <div className="section-title">
              <h2>Edital de Contratação de Recursos Humanos</h2>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <a href="https://drive.google.com/file/d/1atVCOQYa8CNv80UnsVKnFGuplo3f02Wy/view" target="_blank" rel="noreferrer">
                  <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                    <div className="icon">
                      <img className="pdf-icon" src="/assets/img/pdf-icon.png" alt="" />
                    </div>
                    <h4>Edital de Contratação de RH - 2023 </h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Main>
  )
}